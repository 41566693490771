<template>
  <div>
    <v-row>
      <v-col>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Bestillinger
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :items="orders"
          :headers="headers"
        >
          <template #[`item.orderedOn`]="{ item }">
            {{ item.orderedOn | formatDate }}
          </template>
          <template #[`item.expectedDelivery`]="{ item }">
            {{ item.expectedDelivery | formatDate }}
          </template>
          <template #[`item.receivedOn`]="{ item }">
            {{ item.receivedOn | formatDate }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              icon
              small
              :to="item.id"
              append
            >
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-menu
          v-model="createMenu"
          offset-y
          :close-on-content-click="false"
        >
          <template #activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :disabled="!suppliers.length"
              :loading="loading"
            >
              Opret bestilling
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              Vælg leverandør
            </v-card-title>

            <v-card-text>
              <v-form ref="form">
                <v-autocomplete
                  v-model="supplierId"
                  :items="suppliers"
                  label="Leverandør"
                  item-text="title"
                  item-value="id"
                  :rules="[ v => !!v || 'Skal udfyldes' ]"
                />
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" @click="createOrder">
                Opret
                <v-icon right> mdi-creation </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SupplierOrders',
  data() {
    return {
      createMenu: false,
      loading: false,
      orders: [],
      headers: [
        { text: 'Ordrenummer', value: 'orderNumber' },
        { text: 'Ektern ref.', value: 'externalOrderRef' },
        { text: 'Bestilt af', value: 'orderedBy_Person.fullName' },
        { text: 'Leverandør', value: 'supplier.title' },
        { text: 'Bestillingsdato', value: 'orderedOn' },
        { text: 'Forventet levering', value: 'expectedDelivery' },
        { text: 'Antal varer', value: 'lines' },
        { text: 'Modtaget', value: 'receivedOn' },
        { text: 'Anulleret', value: 'cancelledOn' },
        { text: '', value: 'actions', align: 'end' }
      ],
      supplierId: '',
      suppliers: []
    }
  },
  methods: {
    async createOrder() {
      const userId = this.$store.getters.userId;

      if (!this.$refs.form.validate()) return;

      const form = new FormData();
      form.append('orderedBy', userId);
      form.append('supplierId', this.supplierId);

      try {
        const response = await this.$AuthService.api.post('supplier-orders', form);

        this.orders.push(response.data);

        this.$notifier.showSuccess({ message: 'Bestilling blev oprettet' });

        this.createMenu = false;
        this.supplierId = '';
        this.$refs.form.resetValidation();
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      }
    }
  },
  async mounted() {
    try {
      this.loading = true;

      const orders = await this.$AuthService.api.get(`supplier-orders`);
      const suppliers = await this.$AuthService.api.get('supplier');

      this.orders = orders.data;
      this.suppliers = suppliers.data
    } catch (error) {
      console.error(error);
      this.$notifier.showError({});
    } finally {
      this.loading = false;
    }
  }
}
</script>
